import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './components/app/app.component';

import { routes } from 'app/routes';

import { Api } from 'app/services/api';
import { Window } from 'app/services/window';
import { CardPaymentComponent } from './components/card-payment/card-payment.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CountryService } from './services/countries';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, CardPaymentComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        HttpClientModule,
        RouterModule.forRoot(routes),
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule
    ],
    providers: [Api, Window, CountryService]
})
export class AppModule {}
