<!DOCTYPE html>
<div class="app-container d-flex flex-column">
  <div class="content-container">
    <div class="header"><img class="background" src="/gradient-bg.png"><img class="logo" src="/advipay-logo.png" alt="Advicator Payments" title="Advicator Payments">
      <h4 class="header-text">SECURE PAYMENT SOLUTIONS</h4>
    </div>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>