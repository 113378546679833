<!DOCTYPE html>
<div class="container">
  <div class="row" *ngIf="model">
    <div class="col text-center mt-40">
      <h2>Secure and easy checkout with Advicator</h2>
      <p class="info">All transactions are secure and encrypted.</p>
      <p class="info2">Card information is never stored.</p>
    </div>
  </div>
  <div class="row order-styling">
    <div class="col shopping-div">
      <div class="icon">
        <fa-icon class="cart-icon" [icon]="faShoppingCart"></fa-icon>
      </div>
      <div class="price">
        <p class="info-text">Order No.</p>
        <h2 class="info-text2">{{ model?.incoming_order_uid }}</h2>
      </div>
    </div>
    <div class="col total-div">
      <div class="icon">
        <fa-icon class="receipt-icon" [icon]="faReceipt"></fa-icon>
      </div>
      <div class="price">
        <p class="info-text">Total Due</p>
        <h2 class="info-text2">{{ model?.total | currency : model?.currency : 'symbol-narrow' }} </h2>
      </div>
    </div>
  </div>
  <div class="row tab-styling"> 
    <div class="col tab" [class.active-tab]="billing" (click)="showBilling()"><strong>Billing Address</strong></div>
    <div class="col tab" [class.active-tab]="cardInfo" (click)="showCardInfo()"><strong>Card Information</strong></div>
  </div>
  <form class="mt-15" [formGroup]="formGroup" (submit)="submit()" *ngIf="model">
    <div class="billingInfo" *ngIf="billing">
      <div class="row max-height">
        <div class="col">
          <label>ADDRESS</label>
          <input placeholder="Address" formControlName="address_1" [class.border-error]="!formGroup.controls.address_1.valid &amp;&amp; formGroup.controls.address_1.touched">
          <div class="error" *ngIf="!formGroup.controls.address_1.valid &amp;&amp; formGroup.controls.address_1.touched">
            <p *ngIf="formGroup.controls.address_1.hasError('required')">This is required.</p>
            <p *ngIf="formGroup.controls.address_1.hasError('maxlength')">This is too long.</p>
            <p *ngIf="formGroup.controls.address_1.hasError('invalid')">This is invalid.</p>
          </div>
        </div>
      </div>
      <div class="row max-height">
        <div class="col">
          <label>TOWN/CITY</label>
          <input placeholder="City" formControlName="city" [class.border-error]="!formGroup.controls.city.valid &amp;&amp; formGroup.controls.city.touched">
          <div class="error" *ngIf="!formGroup.controls.city.valid &amp;&amp; formGroup.controls.city.touched">
            <p *ngIf="formGroup.controls.city.hasError('required')">This is required.</p>
            <p *ngIf="formGroup.controls.city.hasError('maxlength')">This is too long.</p>
            <p *ngIf="formGroup.controls.city.hasError('invalid')">This is invalid.</p>
          </div>
        </div>
      </div>
      <div class="row max-height">
        <div class="col">
          <label>ZIP / POSTCODE</label>
          <input placeholder="00000" formControlName="postcode" [class.border-error]="!formGroup.controls.postcode.valid &amp;&amp; formGroup.controls.postcode.touched">
          <div class="error" *ngIf="!formGroup.controls.postcode.valid &amp;&amp; formGroup.controls.postcode.touched">
            <p *ngIf="formGroup.controls.postcode.hasError('required')">This is required.</p>
            <p *ngIf="formGroup.controls.postcode.hasError('maxlength')">This is too long.</p>
            <p *ngIf="formGroup.controls.postcode.hasError('invalid')">This is invalid.</p>
          </div>
        </div>
        <div class="col">
          <label>COUNTRY</label>
          <ng-select class="countries" placeholder="Select country" formControlName="country" [items]="countries" bindValue="value" bindLabel="name" [clearable]="false" [class.border-error]="!formGroup.controls.country.valid &amp;&amp; formGroup.controls.country.touched"></ng-select>
          <div class="error" *ngIf="!formGroup.controls.country.valid &amp;&amp; formGroup.controls.country.touched">
            <p *ngIf="formGroup.controls.country.hasError('required')">This is required.</p>
            <p *ngIf="formGroup.controls.country.hasError('maxlength')">This is too long.</p>
            <p *ngIf="formGroup.controls.country.hasError('invalid')">This is invalid.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="cardInfo" *ngIf="cardInfo">
      <div class="row max-height">
        <div class="col">
          <label>CARDHOLDER NAME</label>
          <input placeholder="Mr Joe Blogs" formControlName="name" [class.border-error]="!formGroup.controls.name.valid &amp;&amp; formGroup.controls.name.touched" autocomplete="cc-name">
          <div class="error" *ngIf="!formGroup.controls.name.valid &amp;&amp; formGroup.controls.name.touched">
            <p *ngIf="formGroup.controls.name.hasError('required')">This is required.</p>
            <p *ngIf="formGroup.controls.name.hasError('maxlength')">This is too long.</p>
            <p *ngIf="formGroup.controls.name.hasError('invalid')">This is invalid.</p>
          </div>
        </div>
      </div>
      <div class="row max-height" *ngIf="model?.cards">
        <div class="col">
          <label>CARD NUMBER {{type}}</label>
          <input placeholder="---- ---- ---- ----" formControlName="number" [class.border-error]="!formGroup.controls.number.valid &amp;&amp; formGroup.controls.number.touched" autocomplete="cc-number" #number>
          <fa-icon class="card-icon" *ngIf="type === 'mastercard'" [icon]="faCcMastercard"></fa-icon>
          <fa-icon class="card-icon" *ngIf="type === 'visa'" [icon]="faCcVisa"></fa-icon>
          <fa-icon class="card-icon" *ngIf="type === 'unknown'" [icon]="faCreditCardFront"></fa-icon>
          <div class="error" *ngIf="!formGroup.controls.number.valid &amp;&amp; formGroup.controls.number.touched">
            <p *ngIf="formGroup.controls.number.hasError('required')">This is required.</p>
            <p *ngIf="formGroup.controls.number.hasError('invalid')">This is invalid.</p>
          </div>
        </div>
      </div>
      <div class="row max-height" *ngIf="model?.cards">
        <div class="col">
          <label>EXPIRY DATE</label>
          <input placeholder="MM/YY" formControlName="exp" [class.border-error]="!formGroup.controls.exp.valid &amp;&amp; formGroup.controls.exp.touched" autocomplete="cc-exp" #exp>
          <div class="error" *ngIf="!formGroup.controls.exp.valid &amp;&amp; formGroup.controls.exp.touched">
            <p *ngIf="formGroup.controls.exp.hasError('required')">This is required.</p>
            <p *ngIf="formGroup.controls.exp.hasError('invalid')">This is invalid.</p>
          </div>
        </div>
        <div class="col">
          <label>SECURITY NUMBER</label>
          <input placeholder="CVV" formControlName="cvc" [class.border-error]="!formGroup.controls.cvc.valid &amp;&amp; formGroup.controls.cvc.touched" autocomplete="cc-csc"><span class="card-icon2"><img src="/cvv2.svg"></span>
          <div class="error" *ngIf="!formGroup.controls.cvc.valid &amp;&amp; formGroup.controls.cvc.touched">
            <p *ngIf="formGroup.controls.cvc.hasError('required')">This is required.</p>
            <p *ngIf="formGroup.controls.cvc.hasError('invalid')">This is invalid.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-right">
        <button class="btn btn-primary" *ngIf="billing" type="button" (click)="showCardInfo()">PROCEED TO CARD DETAILS</button>
        <button class="btn btn-primary" *ngIf="cardInfo" type="submit">PLACE ORDER</button>
      </div>
    </div>
    <div class="row terms">
      <div class="col"> 
        <p class="reassure">Advicator Payments is always safe and secured. No card information is stored and sent to the merchant.</p>
      </div>
    </div>
    <div class="row">
      <div class="col text-center"><img class="payment-logo" src="/secure-encryption.png"><img class="secure-logo" src="/norton-secured.png"></div>
    </div>
  </form>
  <div id="loader-container" *ngIf="isLoading">
    <div id="loader">
      <div class="dot"></div>
    </div>
  </div>
</div>