import { Injectable } from '@angular/core';

function _window(): any {
    return window;
}

export enum StorageTypes {
    Local,
    Session
}

export class Storage {
    // A relatively simple Storage container for now
    // We can expand it in the future to allow WebSQL as well
    private _type: StorageTypes;

    constructor(type: StorageTypes = StorageTypes.Local) {
        this._type = type;
    }

    /**
     * Sets a value in the storage container
     * @param string - the key/name of the value to be stored
     * @param any - the value to be stored
     * @return void
     */
    public set(key: string, value: any): void {
        switch (this._type) {
            case StorageTypes.Session:
                _window().sessionStorage.setItem(key, value);
                break;
            case StorageTypes.Local:
            default:
                _window().localStorage.setItem(key, value);
                break;
        }
    }

    /**
     * Gets a value in the storage container
     * @param string - the key/name of the value to be returned
     * @return any/null
     */
    public get(key: string): any | null {
        switch (this._type) {
            case StorageTypes.Session:
                return _window().sessionStorage.getItem(key);
            case StorageTypes.Local:
            default:
                return _window().localStorage.getItem(key);
        }
    }

    /**
     * Removes a value in the storage container
     * @param string - the key/name of the value to be removed
     * @return void
     */
    public remove(key: string): void {
        switch (this._type) {
            case StorageTypes.Session:
                return _window().sessionStorage.removeItem(key);
            case StorageTypes.Local:
            default:
                return _window().localStorage.removeItem(key);
        }
    }
}

@Injectable()
export class Window {
    private _localStorage: Storage;
    private _sessionStorage: Storage;

    constructor() {
        this._localStorage = new Storage(StorageTypes.Local);
        this._sessionStorage = new Storage(StorageTypes.Local);
    }

    /**
     * Returns the native window the app is running in
     * @return window
     */
    get nativeWindow(): any {
        return _window();
    }

    /**
     * Returns the storage container as requested by type parameter
     * @param StorageTypes - the type of storage to return
     * @return Storage
     */
    public storage(type: StorageTypes = StorageTypes.Local): Storage | null {
        switch (type) {
            case StorageTypes.Local:
                return this._localStorage;
            case StorageTypes.Session:
                return this._sessionStorage;
            default:
                return null;
        }
    }

    get localStorage(): Storage {
        return this._localStorage;
    }

    get sessionStorage(): Storage {
        return this._sessionStorage;
    }
}
