import { Component, OnDestroy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators, ValidationErrors, FormControl, ValidatorFn } from '@angular/forms';
import { Api } from 'app/services/api';
import { HttpHeaders } from '@angular/common/http';
import { CountryService } from 'app/services/countries';
import * as Validator from 'card-validator';
import { faShoppingCart, faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faCreditCardFront } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
declare var Stripe: any;
import Cleave from 'cleave.js';

// tslint:disable object-literal-sort-keys

interface Transaction {
    id: number;
    payment_js_uid: string;
    total: number;
    incoming_order_uid: number;
    full_name: string;
    address_1: string;
    address_2: string;
    postcode: string;
    city: string;
    country: string;
    currency: string;
    stripe: { public_key: string };
    cards: boolean;
}

@Component({
    styleUrls: ['./card-payment.component.scss'],
    templateUrl: './card-payment.component.pug',
})
export class CardPaymentComponent implements OnInit, OnDestroy {
    private _paramsSubscription: Subscription;
    private _token: string;
    private _pkey: string;
    public formGroup: FormGroup;
    public isLoading: boolean = true;
    public model: Transaction = null;
    public showError: boolean = false;
    private _stripe: any;
    public cardErrors: any;
    private _card: any;
    public countries: any[];
    public faShoppingCart: IconDefinition = faShoppingCart;
    public faReceipt: IconDefinition = faReceipt;
    public billing: boolean = true;
    public cardInfo: boolean = false;
    public billingValid: boolean = false;
    public faCcMastercard: IconDefinition = faCcMastercard;
    public faCcVisa: IconDefinition = faCcVisa;
    public faCreditCardFront: IconDefinition = faCreditCardFront;
    public type: string = '';
    private _ccCleave: Cleave = null;

    @ViewChild('number', { static: false }) set number(input: ElementRef) {
        if (input) {
            this._ccCleave = new Cleave(input.nativeElement, {
                creditCard: true,
                onCreditCardTypeChanged: (type) => {
                    setTimeout(() => {
                        this.type = type;
                    });
                },
            });
        } else if (this._ccCleave) {
            this._ccCleave.destroy();
        }
    }

    @ViewChild('exp', { static: false }) set exp(input: ElementRef) {
        if (input) {
            this._ccCleave = new Cleave(input.nativeElement, {
                date: true,
                datePattern: ['m', 'y'],
            });
        } else if (this._ccCleave) {
            this._ccCleave.destroy();
        }
    }

    public ngOnInit() {
        window.onbeforeunload = (event) => {
            event.preventDefault();
            event.returnValue = `Are you sure you want to leave? Your order won't be completed without finishing the payment process.`;
        };
    }

    constructor(private _route: ActivatedRoute, private _builder: FormBuilder, private _api: Api, private _countries: CountryService) {
        this._paramsSubscription = this._route.paramMap.subscribe((data) => {
            if (data.get('token') && data.get('pkey')) {
                this._token = data.get('token');
                this._pkey = data.get('pkey');
                this._fetchOrderDetails();
            }
        });

        this.formGroup = this._builder.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            address_1: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
            city: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            postcode: [null, Validators.compose([Validators.maxLength(20)])],
            country: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],

            number: [null, Validators.compose([this._isCardDetailRequired()])],
            cvc: [null, Validators.compose([this._isCardDetailRequired()])],
            exp: [null, Validators.compose([this._isCardDetailRequired()])],
            exp_month: [null],
            exp_year: [null],
        });
        this.countries = this._countries.getSimpleCountryList();
    }

    public ngOnDestroy() {
        this._paramsSubscription.unsubscribe();
        if (this._ccCleave) {
            this._ccCleave.destroy();
        }
    }
    private _fetchOrderDetails() {
        this._api
            .get('/payments/' + this._token, {
                headers: new HttpHeaders().set('Authorization', this._pkey),
            })
            .subscribe(
                (data) => {
                    this.isLoading = false;
                    if (data) {
                        this.model = data;
                        this.formGroup.setValue({
                            name: this.model.full_name ? this.model.full_name : '',
                            address_1: this.model.address_1 ? this.model.address_1 : '',
                            city: this.model.city ? this.model.city : '',
                            postcode: this.model.postcode ? this.model.postcode : '',
                            country: this.model.country ? this.model.country : '',
                            number: '',
                            cvc: '',
                            exp: '',
                            exp_month: '',
                            exp_year: '',
                        });
                        if (this.model.stripe) {
                            this._prepareStripe();
                        }
                    } else {
                        this.showError = true;
                    }
                },
                (err) => {
                    this.showError = true;
                }
            );
    }
    private _prepareStripe() {
        this._stripe = Stripe(this.model.stripe.public_key);
        const elements = this._stripe.elements();
        const style = {
            base: {
                color: '#333E48',
                lineHeight: '16px',
                fontFamily: '"Helvetica Neue", sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                fontWeight: '400',
                '::placeholder': {
                    color: '#989BA2',
                },
            },
            invalid: {
                color: '#EE2647',
                iconColor: '#EE2647',
            },
        };
        this._card = elements.create('card', {
            style: style,
            hidePostalCode: true,
        });
        this._card.update({
            value: { postalCode: this.formGroup.controls.postcode.value },
        });
        this._card.addEventListener('change', (event) => {
            if (event.error) {
                this.cardErrors = event.error.message;
            } else {
                this.cardErrors = null;
            }
        });
        setTimeout(() => {
            this._card.mount('#card-element');
        }, 50);
    }

    public closeWindow() {
        window.onbeforeunload = null;
        window.close();
    }

    public submit() {
        this.formGroup.markAllAsTouched();
        this._validateCardDetails();
        if (this.formGroup.valid) {
            this.isLoading = true;
            this._api
                .post('/payments/' + this._token, this.formGroup.value, {
                    headers: new HttpHeaders().set('Authorization', this._pkey),
                })
                .subscribe(
                    (data) => {
                        if (data && data.token) {
                            this._processStripe(data);
                        } else {
                            this._finaliseTransaction();
                        }
                    },
                    (err) => {
                        this.showError = true;
                        this.isLoading = false;
                    }
                );
        }
    }
    private _validateCardDetails() {
        if (this.model && this.model.cards) {
            const numberValidation = Validator.number(this.formGroup.controls.number.value);
            if (!numberValidation.isPotentiallyValid) {
                this.formGroup.controls.number.setErrors({ invalid: true });
            }
            this.formGroup.controls.exp_month.setValue(null);
            this.formGroup.controls.exp_year.setValue(null);
            const expiry = Validator.expirationDate(this.formGroup.controls.exp.value);
            if (!expiry.isPotentiallyValid) {
                this.formGroup.controls.exp.setErrors({ invalid: true });
            } else {
                this.formGroup.controls.exp_month.setValue(expiry.month);
                this.formGroup.controls.exp_year.setValue(+expiry.year < 100 ? +expiry.year + 2000 : +expiry.year);
            }
            const cvc = Validator.cvv(this.formGroup.controls.cvc.value, numberValidation.card ? numberValidation.card.code.size : 3);
            if (!cvc.isPotentiallyValid) {
                this.formGroup.controls.cvc.setErrors({ invalid: true });
            }
        }
    }
    private _processStripe(data: any) {
        this._stripe
            .handleCardPayment(data.token, this._card, {
                payment_method_data: {
                    billing_details: {
                        name: this.formGroup.controls.name.value,
                        address: {
                            line1: this.formGroup.controls.address_1.value,
                            city: this.formGroup.controls.city.value,
                            postal_code: this.formGroup.controls.postcode.value,
                            country: this.formGroup.controls.country.value,
                        },
                    },
                },
            })
            .then((result) => {
                this._finaliseTransaction();
            });
    }

    private _finaliseTransaction() {
        this._api
            .post('/payments/finalise/' + this._token, {}, { headers: new HttpHeaders().set('Authorization', this._pkey) })
            .subscribe((data) => {
                window.opener?.postMessage(
                    {
                        complete: data?.error === false,
                    },
                    '*'
                );
                window.parent?.postMessage(
                    {
                        complete: data?.error === false,
                    },
                    '*'
                );
                this.closeWindow();
            });
    }
    private _isCardDetailRequired(): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            if (this.model && this.model.cards) {
                if (!control.value) {
                    return { required: true };
                }
            }
            return null;
        };
    }
    public showBilling() {
        this.billing = true;
        this.cardInfo = false;
    }
    public showCardInfo() {
        this.checkBilling();
        if (this.billingValid) {
            this.billing = false;
            this.cardInfo = true;
        } else {
            this.formGroup.controls.address_1.markAsTouched();
            this.formGroup.controls.city.markAsTouched();
            this.formGroup.controls.country.markAsTouched();
        }
    }
    public checkBilling() {
        if (this.formGroup.controls.address_1.valid && this.formGroup.controls.city.valid && this.formGroup.controls.country.valid) {
            this.billingValid = true;
        } else {
            this.billingValid = false;
        }
    }
}
