import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-advicator-payment-js',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.pug'
})
export class AppComponent {
    constructor() {}
}
