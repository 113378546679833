import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { of } from 'rxjs';

// Our API Service which will handle all comms with the backend server
@Injectable()
export class Api {
    private _endPoint = process.env.API;

    private _options: any = {
        headers: null,
        observe: 'response',
        withCredentials: true
    };

    constructor(private _http: HttpClient) {}

    public post(url: string, data: Object, options?: any): Observable<any> {
        // Our wrapper that injects the options into HTTP service
        let httpOptions = _.clone(this._options);
        if (options) {
            httpOptions = this._mergeObjects(httpOptions, options);
        }
        httpOptions.headers = this._prepareHeaders(httpOptions.headers);
        // throw new Error('error');
        return this._http.post(this._endPoint + url, data, httpOptions).pipe(
            map((res: any) => res['body']),
            catchError(this._handleError())
        );
    }

    private _prepareHeaders(headers: HttpHeaders): HttpHeaders {
        return (headers ? headers : new HttpHeaders()).set('X-ANGULAR', '1');
    }

    public get(url: string, options?: Object): Observable<any> {
        // Our wrapper that injects the options into HTTP service
        let httpOptions = _.clone(this._options);
        if (options) {
            httpOptions = this._mergeObjects(httpOptions, options);
        }
        httpOptions.headers = this._prepareHeaders(httpOptions.headers);
        return this._http.get(this._endPoint + url, httpOptions).pipe(
            map((res: any) => res['body']),
            catchError(this._handleError())
        );
    }

    private _handleError(): any {
        return (error: HttpErrorResponse | any) => {
            // Handles the error and throws an error observable
            if (error.status === 403) {
                // this._tabs.navigate('403');
            }
            return of({ error: true, data: error } as any);
        };
    }

    private _mergeObjects(obj1: any, obj2: any): any {
        for (const k in obj2) {
            if (obj1[k]) {
                _.extend(obj1[k], obj2[k]);
            } else {
                obj1[k] = obj2[k];
            }
        }
        return obj1;
    }

    public get endPoint(): string {
        return this._endPoint;
    }
}
